import { Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ArrowUpward, DateRange } from "@material-ui/icons";
import React, { FC } from "react";
import { useStyles } from "./style";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import HeightIcon from '@material-ui/icons/Height';
import { TransactionMethod } from "../../types/graphql-global-types";
import { formatAmountLetter } from "../../utils/helpers";
import Pictogram from "../CustomPithogramme/Pithogramme";

export interface CardProps {
  devise?: string;
  credit: number;
  nameUser: string;
  color: string;
  code: string;
  targetCode?: string;
  shortCode: string;
  date: string;
  label: string;
  transactionMethod: TransactionMethod;
  heure: string;
  isConversion: boolean;
}
export const CardHistories: FC<CardProps> = ({
  devise,
  credit,
  date,
  nameUser,
  label,
  transactionMethod,
  heure,
  code,
  shortCode,
  targetCode,
  color,
  isConversion
}) => {
  const classes = useStyles();

  const transactionMeth = new Map();

  transactionMeth.set("PAYER", "Paiement");
  transactionMeth.set("DON_APPORT", "Don ou Apport");
  transactionMeth.set("TRANSFERT_CYTOIENS", "Transfert citoyens");
  transactionMeth.set("AVANCE_SOLIDAIRE", "Avance solidaire");
  transactionMeth.set("VERSEMENT_SALAIRE", " Versement salaire");
  transactionMeth.set("CONVERSION", "Conversion");
  transactionMeth.set("VALIDATION", "Validation");
  transactionMeth.set("COMMISSIONING", "Mise en service");
  transactionMeth.set("FOURNITURE_PRESTATION", "Fourniture-Prestation");
  transactionMeth.set("IMPOT_TAXE", "Impôt-Taxe");
  transactionMeth.set("SALAIRE_REMUNERATION", "Salaire-Rémunération");
  transactionMeth.set("DEBIT", "(débit) Compte d'associés");
  transactionMeth.set("HEBERGEMENT_PROFESSIONNEL", "Hébergement professionnel");
  transactionMeth.set("ASSURANCE", "Assurance");
  transactionMeth.set("DEBLOCAGE_PRET_AVANCE_REMBOURSABLE", "Déblocage prêt-avance remboursable");
  transactionMeth.set("RETOUR_DES_FONDS_INVESTIS", "Retour des fonds investis");
  transactionMeth.set("DON", "Don");
  transactionMeth.set("VERSMENT_DIVIDENDES", "Versement dividendes");
  transactionMeth.set("FRAIS_FINANCIERE", "Frais financiers");
  transactionMeth.set("REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE", "Remboursement prêt-avance remboursable");
  transactionMeth.set("INVESTISSEMENT_FINANCIER", "Investissement financier");
  transactionMeth.set("AUTRES", "Autres");
  transactionMeth.set("CONSOMMATION_PRESTATION", "Consommation-Prestation");
  transactionMeth.set("SOLIDARITE_CONTRIBUTION", "Solidarité et contribution");
  transactionMeth.set("CREDIT", "(crédit)Compte d'associés");
  transactionMeth.set("HEBERGEMENT_PRIVATIF", "Hébergement privatif");
  transactionMeth.set("REMBOURSEMENT_PRET", "Remboursement prêt");
  transactionMeth.set("DON_POURBOIR", "Don-Pourboire");

  return (
    <Card className={classes.cardRoot}>
      <CardContent className={classes.cardcontent}>
        <Box className={classes.boxcard}>
          <Box className={classes.boxTop}>
            <Pictogram color={color || '#4B7FD5'} shortCode={shortCode || ''} />
            <Box className={classes.details}>
              <Typography variant="h5">{code}</Typography>
              <Typography variant="h6">
                {formatAmountLetter(
                  new Intl.NumberFormat("fr-FR").format(credit)
                )}
              </Typography>
              {
                isConversion ?
                (
                  <Typography variant="h5">{targetCode}</Typography>
                ) : 
                (
                  <Typography style={{ fontSize: "10px" }}>
                    {transactionMeth.get(transactionMethod)}
                  </Typography>
                )
              }
            </Box>
          </Box>
          <Box className={classes.arrowIcon}>
            {
              isConversion 
              ? (
                <HeightIcon style={{ color: "#6F56FF" }} />
              ) : label === "SENT" ? (
                <ArrowDownwardIcon style={{ color: "#F35162" }} />
              ) : (
                <ArrowUpward style={{ color: "#2EC114" }} />
              )
            }
          </Box>
        </Box>
        <Box className={classes.boxText}>
          <Typography className={classes.pos} variant="h6">
            {nameUser}
          </Typography>
          <Box className={classes.date}>
            <Box>
              <DateRange />
            </Box>
            <Box>
              <Typography className={classes.dateText}>{date}</Typography>
              <Typography className={classes.dateText}>{heure}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
