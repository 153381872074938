import {
  Box,
  Button,
  createMuiTheme,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import clsx from "clsx";
import { useStyles } from './style';
import CardNotice from '../../../../common/cardeNotice/CardNotice';
import {
  TEXTÈ_CONVERSION_COIN,
} from '../../../../constant/TextNotice/TextNotice';
import { initSnackBarStatus, SnackBarStatus } from '../types';
import { ConversionToEUCoin, ConversionToEUCoinVariables } from '../../../../graphql/ConversionCoin/__generated__/ConversionToEUCoin';
import { CONVERSION_TO_EU_COIN } from '../../../../graphql/ConversionCoin/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CustomModal } from '../../../../common/customModal/CustomModal';
import { CustomSnackBar } from '../../../../common/CustomSnackBar/CustomSnackBar';
import { CustomBackdrop } from '../../../../common/BackDrop/BackDrop';
import CustomAutoComplete from '../../../../common/CustomAutocomplette/CustomAutoComplete';
import { COIN_OPTIONS } from '../../../../graphql/autocompleteOptions/coinOptions';
import { Coin__Options } from '../../../../graphql/autocompleteOptions/__generated__/Coin__Options';
const EUCoin: React.FC = () => {
  const style = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });
  const [coinCode, setCoinCode] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [snackBarStatus, setSnackBarStatus] = useState<SnackBarStatus>(initSnackBarStatus);

  const [DoConversion, {loading} ] = useMutation<
    ConversionToEUCoin,
    ConversionToEUCoinVariables
  >(CONVERSION_TO_EU_COIN, {
    onCompleted: () => {
      setSnackBarStatus((prev) => ({
        ...prev,
        open: true,
        type: 'success',
        message: 'Conversion réussie !'
      }));
      setCoinCode('');
      setAmount('');
    },
    onError: (errors) => {
      setSnackBarStatus((prev) => ({
        ...prev,
        open: true,
        type: 'error',
        message: errors.message
      }));
    },
  });

  const [getCoinOptions, { data: options, loading: coinLoading }] =
    useLazyQuery<Coin__Options>(COIN_OPTIONS, {
      variables: {},
      onError: (error) => {
        error.graphQLErrors.map((error) => {
          console.log("error==>", error);
          return error;
        });
      },
    });


  const handleClick = () => {
    if (coinCode === '' || amount === '' || Number(amount) < 0) {
      let message = '';
      if (coinCode === '') {
        message = 'Veuillez entrer le nom de la monnaie.';
      } else if(amount === '') {
        message = 'Veuillez entrer le nom montant';
      } else {
        message = 'Veuillez entrer un montant positif';
      }
      setSnackBarStatus((prev) => ({
        ...prev,
        open: true,
        type: 'error',
        message: message
      }));
    } else {
      setSnackBarStatus((prev) => ({
        ...prev,
        message: ''
      }));
      DoConversion({
        variables: {
          conversionCoinInput: {
            affinityCurrencyCode: coinCode,
            amount: Number(amount),
          },
        },
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setAmount(value.trim());
  };

  const isOnOpen = () => {
    getCoinOptions();
  };

  const onChangeAutoComplete = (_e: any, value: any) => {
    if (typeof value == "string" && options && options.coinOptions) {
      const newValue = options.coinOptions.find(
        (item) => item && item.value === value.trim()
      );
      if (newValue) {
        newValue.value && setCoinCode(newValue.value);
      } else {
        setCoinCode(value.trim());
      }
    } else {
      setCoinCode(value.value);
    }
  };

  const NOTE_CONVERSION_COIN = TEXTÈ_CONVERSION_COIN.split('{EU-}');

  return (
    <Box className={style.containtWrappe}>
      <CustomModal 
        open={snackBarStatus.open} 
        setOpen={(open) => setSnackBarStatus((prev) => ({
          ...prev,
          open
        }))} 
        maxWidth="sm"
      >
        <Box>{snackBarStatus.message}</Box>
      </CustomModal>
      <Box className={style.wrapper}>
        <Typography className={style.textPM}>
          Monnaie à choisir pour la conversion :
        </Typography>
        <Box className={style.flexContainer}>
          <Typography>
            <span className={style.typography}>EU-</span>
          </Typography>
          <CustomAutoComplete
              id={"id"}
              onOpen={isOnOpen}
              loading={coinLoading}
              className={clsx(style.select, style.autoComplete)}
              options={
                (options &&
                  options.coinOptions &&
                  options.coinOptions.length &&
                  options.coinOptions.filter((uo) => uo !== null)
                ) ||
                []
              }
              inputValue={coinCode}
              optionLabelKey="label"
              placeholder="Nom de la monnaie"
              name="coinCode"
              onChange={(e, value) => onChangeAutoComplete(e, value)}
              optionStyle={clsx(style.optionStyle)}
            />
        </Box>
        <Box>
          <Typography className={style.textPM}>
            Montant à convertir:
          </Typography>
          <Box>
            <TextField
              className={style.textField}
              type="number"
              name="amount"
              defaultValue={0}
              variant="outlined"
              value={amount}
              onChange={handleInputChange}
              inputProps={{
                min: 0
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={style.buttonContainer}>
        <ThemeProvider theme={theme}>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            size="medium"
            className={style.btnConfirmer}
            disabled={loading}
          >
            {loading ? 'CONVERSION en cours...' : 'CONVERTIR en EU-coins'}
          </Button>
        </ThemeProvider>
      </Box>
      <Box className={style.cardNotice}>
        <CardNotice width="80%">
            {NOTE_CONVERSION_COIN[0]} 
            <span className={style.codeCurrency}>EU-{coinCode!=='' ? coinCode : 'xxx'}</span>
            {NOTE_CONVERSION_COIN[1]} 
            <span className={style.codeCurrency}>EU-coins</span>
            {NOTE_CONVERSION_COIN[2]}
        </CardNotice> 
      </Box>

      <CustomSnackBar
        open={snackBarStatus.open}
        setOpen={(open) => setSnackBarStatus((prev) => ({
          ...prev,
          open
        }))}
        message={snackBarStatus.message}
        type={snackBarStatus.type}
      />
      <CustomBackdrop open={loading} />
    </Box>
  );
};
export default EUCoin;
