/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Methode de transfert
 */
export enum TransactionMethod {
  ASSURANCE = "ASSURANCE",
  AUTRES = "AUTRES",
  AVANCE_SOLIDAIRE = "AVANCE_SOLIDAIRE",
  COMMISSIONING = "COMMISSIONING",
  CONSOMMATION_PRESTATION = "CONSOMMATION_PRESTATION",
  CONVERSION = "CONVERSION",
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  DEBLOCAGE_PRET_AVANCE_REMBOURSABLE = "DEBLOCAGE_PRET_AVANCE_REMBOURSABLE",
  DON = "DON",
  DON_APPORT = "DON_APPORT",
  DON_POURBOIR = "DON_POURBOIR",
  FOURNITURE_PRESTATION = "FOURNITURE_PRESTATION",
  FRAIS_FINANCIERE = "FRAIS_FINANCIERE",
  HEBERGEMENT_PRIVATIF = "HEBERGEMENT_PRIVATIF",
  HEBERGEMENT_PROFESSIONNEL = "HEBERGEMENT_PROFESSIONNEL",
  IMPOT_TAXE = "IMPOT_TAXE",
  INITIALISATION = "INITIALISATION",
  INVESTISSEMENT_FINANCIER = "INVESTISSEMENT_FINANCIER",
  PAYER = "PAYER",
  REMBOURSEMENT_PRET = "REMBOURSEMENT_PRET",
  REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE = "REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE",
  RETOUR_DES_FONDS_INVESTIS = "RETOUR_DES_FONDS_INVESTIS",
  SALAIRE_REMUNERATION = "SALAIRE_REMUNERATION",
  SOLIDARITE_CONTRIBUTION = "SOLIDARITE_CONTRIBUTION",
  TRANSFERT_CYTOIENS = "TRANSFERT_CYTOIENS",
  VALIDATION = "VALIDATION",
  VERSEMENT_SALAIRE = "VERSEMENT_SALAIRE",
  VERSMENT_DIVIDENDES = "VERSMENT_DIVIDENDES",
}

export interface GetArchiveArgs {
  coinId?: string | null;
  userId?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  skip?: number | null;
  take?: number | null;
  personType?: string | null;
}

export interface GetTransactionArgs {
  currency?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  skip?: number | null;
  take?: number | null;
  transactionType?: string | null;
  transactionMethod?: TransactionMethod | null;
  userId?: string | null;
}

export interface LegalEntityPayload {
  legalEntityTypeId?: string | null;
  denomination?: string | null;
}

export interface TransactionInput {
  amount: number;
  userRecipientId: string;
  transactionMethod: TransactionMethod;
  currencyId: number;
}

export interface conversionCoinInput {
  affinityCurrencyCode: string;
  amount: number;
}

export interface createCoinInput {
  code: string;
}

export interface loginInput {
  id: string;
  password: string;
}

export interface putIntoCirculationInput {
  coinIds?: (string | null)[] | null;
}

export interface signUpInput {
  lastName?: string | null;
  firstName?: string | null;
  isLegalEntity?: boolean | null;
  legalEntity?: LegalEntityPayload | null;
  id: string;
  password: string;
}

export interface updateSettingInput {
  id: string;
  amountOfCreationCostLegalEntity?: number | null;
  amountOfCreationCostNaturalPerson?: number | null;
  amountOfValidationCost?: number | null;
  payoutAmount?: number | null;
  numberOfValidatorsLegalEntity?: number | null;
  numberOfValidatorsNaturalPerson?: number | null;
}

export interface validateCoinInput {
  coinCode: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
