import { TransactionMethod } from "../../types/graphql-global-types";

export const PERIODE_OPTIONS = [
  { value: 1, textName: "Année" },
  { value: 2, textName: "Mois" },
  { value: 3, textName: "Date" },
];

export const TRANSACTION_OPTIONS = [
  { value: "all", textName: "Tous les sens" },
  { value: "SENT", textName: "Débit" },
  { value: "RECEIVED", textName: "Crédit" },
];

export const PERSON_TYPE_OPTIONS = [
  { value: "all", textName: "Tous les types" },
  { value: "PP", textName: "Physique" },
  { value: "PM", textName: "Morale" },
];

export const VALUES_OPTIONS = [
  { value: 0, textName: "Toutes les valeurs" },
  { value: 1, textName: "Coins" },
  { value: 2, textName: "CCoins" },
  { value: 3, textName: "CCCoins" },
];

export const TRANSACTION_METHOD_ADMIN = [
  { value: "all", textName: "Toutes les transactions" },
  { value: TransactionMethod.VALIDATION, textName: "Validation" },
  { value: TransactionMethod.CONVERSION, textName: "Conversion" },
  { value: TransactionMethod.COMMISSIONING, textName: "Mise en service" },
  { value: '', textName: 'Personne physique', showDivider: true },
  { value: TransactionMethod.CONSOMMATION_PRESTATION, textName: "Consommation-Prestation" },
  { value: TransactionMethod.SOLIDARITE_CONTRIBUTION, textName: "Solidarité et contribution" },
  { value: TransactionMethod.CREDIT, textName: "(crédit)Compte d'associés" },
  { value: TransactionMethod.HEBERGEMENT_PRIVATIF, textName: "Hébergement privatif" },
  { value: TransactionMethod.REMBOURSEMENT_PRET, textName: "Remboursement prêt" },
  { value: TransactionMethod.DON_POURBOIR, textName: "Don-Pourboire" },
  { value: '', textName: ' Personne morale', showDivider: true },
  { value: TransactionMethod.FOURNITURE_PRESTATION, textName: "Fourniture-Prestation" },
  { value: TransactionMethod.SALAIRE_REMUNERATION, textName: "Salaire-Rémunération" },
  { value: TransactionMethod.DEBIT, textName: "(débit) Compte d'associés" },
  { value: TransactionMethod.HEBERGEMENT_PROFESSIONNEL, textName: "Hébergement professionnel" },
  { value: TransactionMethod.DEBLOCAGE_PRET_AVANCE_REMBOURSABLE, textName: "Déblocage prêt-avance remboursable" },
  { value: TransactionMethod.RETOUR_DES_FONDS_INVESTIS, textName: "Retour des fonds investis" },
  { value: TransactionMethod.DON, textName: "Don" },
  { value: TransactionMethod.VERSMENT_DIVIDENDES, textName: "Versement dividendes" },
  { value: TransactionMethod.REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE, textName: "Remboursement prêt-avance remboursable" },
  { value: '', textName: 'Personne physique et morale', showDivider: true },
  { value: TransactionMethod.IMPOT_TAXE, textName: "Impôt-Taxe" },
  { value: TransactionMethod.ASSURANCE, textName: "Assurance" },
  { value: TransactionMethod.INVESTISSEMENT_FINANCIER, textName: "Investissement financier" },
  { value: TransactionMethod.FRAIS_FINANCIERE, textName: "Frais financiers" },
  { value: TransactionMethod.AUTRES, textName: "Autres" },
];

export const TRANSACTION_METHOD_NATURAL_PERSON = [
  { value: "all", textName: "Toutes les transactions" },
  { value: TransactionMethod.CONVERSION, textName: "Conversion" },
  { value: TransactionMethod.VALIDATION, textName: "Validation" },
  { value: TransactionMethod.COMMISSIONING, textName: "Mise en service" },
  { value: '', textName: 'Personne physique', showDivider: true },
  { value: TransactionMethod.CONSOMMATION_PRESTATION, textName: "Consommation-Prestation" },
  { value: TransactionMethod.IMPOT_TAXE, textName: "Impôt-Taxe" },
  { value: TransactionMethod.SOLIDARITE_CONTRIBUTION, textName: "Solidarité et contribution" },
  { value: TransactionMethod.CREDIT, textName: "(crédit)Compte d'associés" },
  { value: TransactionMethod.HEBERGEMENT_PRIVATIF, textName: "Hébergement privatif" },
  { value: TransactionMethod.ASSURANCE, textName: "Assurance" },
  { value: TransactionMethod.REMBOURSEMENT_PRET, textName: "Remboursement prêt" },
  { value: TransactionMethod.INVESTISSEMENT_FINANCIER, textName: "Investissement financier" },
  { value: TransactionMethod.DON_POURBOIR, textName: "Don-Pourboire" },
  { value: TransactionMethod.FRAIS_FINANCIERE, textName: "Frais financiers" },
  { value: TransactionMethod.AUTRES, textName: "Autres" },
];

export const TRANSACTION_METHOD_LEGAL_PERSON = [
  { value: "all", textName: "Toutes les transactions" },
  { value: TransactionMethod.CONVERSION, textName: "Conversion" },
  { value: TransactionMethod.COMMISSIONING, textName: "Mise en service" },
  { value: '', textName: 'Personne morale', showDivider: true },
  { value: TransactionMethod.FOURNITURE_PRESTATION, textName: "Fourniture-Prestation" },
  { value: TransactionMethod.IMPOT_TAXE, textName: "Impôt-Taxe" },
  { value: TransactionMethod.SALAIRE_REMUNERATION, textName: "Salaire-Rémunération" },
  { value: TransactionMethod.DEBIT, textName: "(débit) Compte d'associés" },
  { value: TransactionMethod.HEBERGEMENT_PROFESSIONNEL, textName: "Hébergement professionnel" },
  { value: TransactionMethod.ASSURANCE, textName: "Assurance" },
  { value: TransactionMethod.DEBLOCAGE_PRET_AVANCE_REMBOURSABLE, textName: "Déblocage prêt-avance remboursable" },
  { value: TransactionMethod.RETOUR_DES_FONDS_INVESTIS, textName: "Retour des fonds investis" },
  { value: TransactionMethod.DON, textName: "Don" },
  { value: TransactionMethod.VERSMENT_DIVIDENDES, textName: "Versement dividendes" },
  { value: TransactionMethod.FRAIS_FINANCIERE, textName: "Frais financiers" },
  { value: TransactionMethod.REMBOURSEMENT_PRET_AVANCE_REMBOURSABLE, textName: "Remboursement prêt-avance remboursable" },
  { value: TransactionMethod.INVESTISSEMENT_FINANCIER, textName: "Investissement financier" },
  { value: TransactionMethod.AUTRES, textName: "Autres" },
]
